import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbar/Header";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Header />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    FAQs<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">FAQs</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <div id="content">
            <div class="container">
              <h2 className="text-center">Frequently Asked Questions</h2>

              {/* Question 1 */}

              <div class="row">
                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-1">
                    How Do I Maintain My EV Bike?
                  </div>
                  <div class="accordion-section-content" id="accordion-1">
                    <p>
                      Maintaining an EV bike is simple and hassle-free. Keep
                      your bike clean, ensure the battery is charged, and avoid
                      exposing it to wet areas while charging. Unlike
                      traditional engine bikes that need regular maintenance of
                      the engine, brakes, clutch, and gears, EV bikes have fewer
                      moving parts and require minimal care. Just check your
                      tire pressure, brake condition, and battery health
                      periodically to ensure optimal performance and a smooth
                      ride. If you have any questions or need assistance, feel
                      free to call our customer service center.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-2">
                    How long is the battery warranty?
                  </div>
                  <div class="accordion-section-content" id="accordion-2">
                    <p>
                      The battery warranty for Atom-Power electric bikes in
                      Pakistan is 3 years or 40,000 KM, whichever comes first.
                      This warranty applies to issues specified by the company
                      in the warranty booklet. Any breach of the regulations not
                      covered under the warranty terms will not be eligible for
                      coverage.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-3">
                    How long do I need to charge my Electric Bike?
                  </div>
                  <div class="accordion-section-content" id="accordion-3">
                    <p>
                      Our current EV-125 variant has a battery capacity of
                      32Ah/72V, which requires approximately 2.3 units of
                      electricity to fully charge. It can be charged within 2
                      hours using a company provided charging plug.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-4">
                    Is driving an EV bike easy?
                  </div>
                  <div class="accordion-section-content" id="accordion-4">
                    <p>
                      Yes, riding an EV bike is really easy, as it doesn’t have
                      a gear or clutch to adjust whenever you change speeds. Our
                      electric bike offers three speed modes—Eco, City, and
                      Sports—that you can switch between with the push of a
                      button.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-5">
                    What special arrangements do I need to make to charge my EV
                    bike?
                  </div>
                  <div class="accordion-section-content" id="accordion-5">
                    <p>
                      Electric vehicles can be charged at home using a standard
                      electrical outlet or a dedicated home charging station.
                      Just ensure the outlet is properly grounded and avoid
                      using extension cords for safety. Additionally, it’s best
                      to keep the charging area dry and well-ventilated to
                      protect the battery and charging equipment.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-6">
                    What if I have any issues with my Battery?
                  </div>
                  <div class="accordion-section-content" id="accordion-6">
                    <p>
                      If your battery encounters any issues during the warranty
                      period, simply call our helpline, and our customer service
                      team will guide you through the battery replacement
                      process. To ensure prompt assistance, our customer service
                      team is on high alert. Once we receive your query, we will
                      notify our remote team, stationed at various locations in
                      the city. Our trained remote team will visit you at your
                      location to conduct an initial inspection. If the issue
                      can be resolved on-site, we will do so immediately.
                      Otherwise, we will transport your bike to our showroom,
                      make the necessary repairs, and deliver it back to you
                      within 24 hours.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-7">
                    What if my rear tire gets punctured?
                  </div>
                  <div class="accordion-section-content" id="accordion-7">
                    <p>
                      Our battery is located near the rear tire, which can make
                      it tricky to replace the tire if it gets punctured. To
                      assist you, we have created a tutorial to help you change
                      the tires.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-8">
                    Can I take my EV bike out when it's raining?
                  </div>
                  <div class="accordion-section-content" id="accordion-8">
                    <p>
                      Yes, you can easily ride your bike in the rain or other
                      challenging conditions, as it features a waterproof
                      battery box and a robust body. Additionally, our tubeless
                      17-inch tires and alloy rims enhance the bike's
                      performance on difficult terrains, improving
                      maneuverability and control.
                    </p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="accordion-section-title" data-tab="#accordion-9">
                    How far can electric vehicles travel on a single charge?
                  </div>
                  <div class="accordion-section-content" id="accordion-9">
                    <p>
                      Our EV-125 provides a maximum range of 100 KM on a single
                      charge when riding in Eco mode. The range may vary based
                      on the bike’s battery capacity, speed, and tire pressure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- content close --> */}

        <Footer />
      </div>
    </>
  );
};

export default FAQ;
