import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const { WHATSAPP_NUMBER } = window["appconfig"];
const formattedWhatsAppNumber = WHATSAPP_NUMBER.replace(/\D/g, "");
const WhatsAppButton = () => {
  return (
    <a
      style={styles.container}
      href={`https://wa.me/${formattedWhatsAppNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faWhatsapp} style={styles.icon} />
    </a>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#25D366",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: 1000,
  },
  icon: {
    color: "white",
    fontSize: "30px",
  },
};

export default WhatsAppButton;
