// src/PageTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  initializeAnalytics,
  logPageView,
  initializeMetaPixel,
} from "./analytics";

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics();
    initializeMetaPixel();
  }, []);

  useEffect(() => {
    console.log(location.pathname, "location.pathname");
    logPageView(location.pathname);
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "PageView", { page_path: location.pathname }); // Meta Pixel
    }
  }, [location]);

  return null;
};

export default PageTracker;
