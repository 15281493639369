// import React, { lazy, Suspense } from "react";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Home from "../screens/Home";
// import ContactUs from "../screens/ContactUs";
// import OurStory from "../screens/OurStory";
// import EV1 from "../screens/EV1";
// import EV125 from "../screens/EV125";
// import EV500 from "../screens/EV500";
// import PrivacyPolicy from "../screens/PrivacyPolicy";
// import FAQ from "../screens/FAQ";
// import Dealers from "../screens/Dealers";
// import TestDrive from "../screens/TestDrive";

// // Auth routes
// import Login from "../screens/Auth/Login";

// // Erp Routes
// import Dashboard from "../screens/Dashboard";
// import AddBike from "../screens/Dashboard/AddBike";
// import ListBikes from "../screens/Dashboard/ListBikes";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//   },

//   {
//     path: "/contact-us",
//     element: <ContactUs />,
//   },
//   {
//     path: "/about-us",
//     element: <OurStory />,
//   },

//   {
//     path: "/dealers",
//     element: <Dealers />,
//   },
//   {
//     path: "/book-a-test-drive",
//     element: <TestDrive />,
//   },

//   {
//     path: "/ev-1",
//     element: <EV1 />,
//   },

//   {
//     path: "/ev-125",
//     element: <EV125 />,
//   },
//   {
//     path: "/ev-500",
//     element: <EV500 />,
//   },
//   {
//     path: "/privacy-policy",
//     element: <PrivacyPolicy />,
//   },
//   {
//     path: "/faqs",
//     element: <FAQ />,
//   },

//   // Erp System Routes

//   {
//     path: "/evsystem/dashboard",
//     element: (
//       <Suspense fallback={<div>Loading...</div>}>
//         <Dashboard />
//       </Suspense>
//     ),
//     children: [
//       {
//         path: "add-bike",
//         element: (
//           <Suspense fallback={<div>Loading...</div>}>
//             <AddBike />
//           </Suspense>
//         ),
//       },
//       {
//         path: "list-bikes",
//         element: (
//           <Suspense fallback={<div>Loading...</div>}>
//             <ListBikes />
//           </Suspense>
//         ),
//       },
//     ],
//   },

//   {
//     path: "/evsystem/login",
//     element: <Login />,
//   },
// ]);

// function Router() {
//   return <RouterProvider router={router} />;
// }

// export default Router;
// src/config/router.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../screens/Home";
import ContactUs from "../screens/ContactUs";
import OurStory from "../screens/OurStory";
import EV1 from "../screens/EV1";
import EV125 from "../screens/EV125";
import EV500 from "../screens/EV500";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import FAQ from "../screens/FAQ";
import Dealers from "../screens/Dealers";
import TestDrive from "../screens/TestDrive";

// Auth routes
import AuthGuard from "./AuthGuard";
import Login from "../screens/Auth/Login";

// Erp Routes
import Dashboard from "../screens/Dashboard";
import AddBike from "../screens/Dashboard/AddBike";
import ListBikes from "../screens/Dashboard/ListBikes";
import AddPurchaseOrder from "../screens/Dashboard/AddPurchaseOrder";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<OurStory />} />
      <Route path="/dealers" element={<Dealers />} />
      <Route path="/book-a-test-drive" element={<TestDrive />} />
      <Route path="/ev-1" element={<EV1 />} />
      <Route path="/ev-125" element={<EV125 />} />
      <Route path="/ev-500" element={<EV500 />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<FAQ />} />

      {/* Ev system routes */}
      <Route path="/evsystem/login" element={<Login />} />
      <Route
        path="/evsystem/dashboard"
        element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        }
      >
        <Route path="add-bike" element={<AddBike />} />
        <Route path="list-bikes" element={<ListBikes />} />
        <Route path="add-purchase-order" element={<AddPurchaseOrder />} />
      </Route>
    </Routes>
  );
}

export default Router;
