// src/analytics/analytics.js
import ReactGA from "react-ga4";
const { TRACKING_ID, META_PIXEL_ID } = window["appconfig"];

export const initializeAnalytics = () => {
  console.log(TRACKING_ID, META_PIXEL_ID, "tracking id----");

  ReactGA.initialize(TRACKING_ID, { debug_mode: true }); // Replace with your actual GA4 tracking ID
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (eventName, params) => {
  ReactGA.event(eventName, params);
};

// var fbq;
// Meta Pixel Initialization
export const initializeMetaPixel = () => {
  if (typeof window.fbq === "undefined" && META_PIXEL_ID) {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    // Initialize Meta Pixel
    window.fbq("init", META_PIXEL_ID);
    window.fbq("track", "PageView");
  } else if (!META_PIXEL_ID) {
    console.warn("Meta Pixel ID is not set.");
  } else {
    console.warn("Meta Pixel is already initialized.");
  }
};

// Meta Pixel Event Logging
export const trackMetaEvent = (eventName, params = {}) => {
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", eventName, params);
  } else {
    console.warn("Meta Pixel is not initialized or fbq is not defined.");
  }
};
