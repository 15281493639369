// // src/screens/Dashboard.js
// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import Sidebar from "../components/Sidebar/Sidebar";
// import { Outlet, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { logout } from "../redux/slices/authSlice";

// const Dashboard = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleLogout = () => {
//     localStorage.removeItem("elfa_token"); // Clear token
//     localStorage.removeItem("elfa_user_data"); // Clear user data
//     navigate("/evsystem/login"); // Redirect to login page after logout
//     dispatch(logout());
//   };
//   return (
//     <Container fluid className="dashboard">
//       <Row>
//         <Col xs={3} md={2} className="sidebar-container">
//           <Sidebar />
//         </Col>
//         <Col xs={9} md={10} className="content-container">
//           <div className="d-flex justify-content-between align-items-center mb-4">
//             <h2>Dashboard</h2>
//             <Button
//               variant="outline-danger"
//               //   className="logout-btn"
//               className="button-fixed"
//               onClick={handleLogout}
//             >
//               Logout
//             </Button>
//           </div>
//           <Outlet />
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Dashboard;

// src/screens/Dashboard.js
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../components/Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/authSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("elfa_token");
    localStorage.removeItem("elfa_user_data");
    navigate("/evsystem/login");
    dispatch(logout());
  };

  return (
    <Container fluid className="dashboard" style={{ height: "100vh" }}>
      <Row>
        <Col xs={3} md={2} className="sidebar-container">
          <Sidebar />
        </Col>
        <Col xs={9} md={10} className="content-container">
          <div
            className="d-flex justify-content-between align-items-center mb-4"
            style={{
              padding: "12px",
              borderBottom: "1px solid #ccc",
              backgroundColor: "#f8f9fa",
              marginLeft: "12px",
            }}
          >
            <h2>Dashboard</h2>
            <Button
              variant="outline-danger"
              className="button-fixed"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
          <div
            className="fit-content"
            // style={{
            //   padding: "1rem",
            //   minHeight: "fit-content",
            // }}
          >
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
