// src/components/Sidebar.js
// import React from "react";
// import { Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const Sidebar = () => {
//   return (
//     <Nav
//       className="flex-column"
//       style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
//     >
//       <h4 className="sidebar-title">Elfa Electric</h4>

//       {/* <Nav.Link as={Link} to="/dashboard/add-bike">
//         Add Bikes
//       </Nav.Link> */}
//       <Nav.Link as={Link} to="/evsystem/dashboard/list-bikes">
//         List Bikes
//       </Nav.Link>
//     </Nav>
//   );
// };

// export default Sidebar;

// src/components/Sidebar.js
import React from "react";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { FaDashboard, FaMotorcycle, FaCog } from "react-icons/fa";

const Sidebar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <Nav
      className="flex-column sidebar"
      style={{
        height: "100vh",
        backgroundColor: "#2c3e50",
        color: "#ecf0f1",
        padding: "1rem",
      }}
    >
      <div
        className="d-flex align-items-center mb-4"
        style={{
          gap: "0.5rem",
        }}
      >
        {/* Logo Image */}
        <img
          src="/images/ELFA-Logo-white.webp" // Adjust the path if necessary
          alt="Elfa Logo"
          style={{
            width: "100%", // Set a fixed width
            height: "24px", // Set a fixed height
            objectFit: "contain", // Ensure the logo retains its aspect ratio
            borderRadius: "50%", // Optional: Make it circular
          }}
        />
      </div>
      <Nav.Link
        as={Link}
        to="/evsystem/dashboard/list-bikes"
        className="sidebar-link"
      >
        <i className="icofont-motor-bike me-2"></i>
        List Bikes
      </Nav.Link>

      {/* Purchase Order Dropdown */}
      <div>
        <div
          className="sidebar-link d-flex justify-content-between align-items-center"
          onClick={toggleDropdown}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            <i className="icofont-cart me-2"></i> Purchase Order
          </span>
          <i
            className={`icofont-rounded-down ${
              showDropdown ? "rotate-180" : ""
            }`}
          ></i>
        </div>
        {showDropdown && (
          <div className="dropdown-menu-sidebar mt-2">
            <Nav.Link
              as={Link}
              to="/evsystem/dashboard/add-purchase-order"
              className="sidebar-link"
            >
              Add Purchase Order
            </Nav.Link>
            {/* <Nav.Link
              as={Link}
              to="/evsystem/dashboard/order-details"
              className="sidebar-link"
            >
              Order Details
            </Nav.Link> */}
          </div>
        )}
      </div>
    </Nav>
  );
};

export default Sidebar;
