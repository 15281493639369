import "./App.css";
import Router from "./config/router";
import { Toaster } from "react-hot-toast";
import PageTracker from "./analytics/PageTracker";
import { ToastContainer } from "react-toastify";
import WhatsAppButton from "./screens/WhatsAppButton/WhatsAppButton";
function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ToastContainer />
      <PageTracker />
      <div>
        <Router />
        <WhatsAppButton />
      </div>
    </>
  );
}

export default App;
