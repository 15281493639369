// src/screens/Dashboard/AddBike.js
import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";

const AddBike = () => {
  const [bikeName, setBikeName] = useState("");
  const [bikeModel, setBikeModel] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Bike added:", { bikeName, bikeModel });
    // Logic for adding the bike
    setBikeName(""); // Reset input fields
    setBikeModel("");
  };

  return (
    <Container>
      <h3>Add Bike</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBikeName">
          <Form.Label>Bike Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bike name"
            value={bikeName}
            onChange={(e) => setBikeName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBikeModel">
          <Form.Label>Model</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter model"
            value={bikeModel}
            onChange={(e) => setBikeModel(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="fixed-size-btn mt-5">
          Add Bike
        </Button>
      </Form>
    </Container>
  );
};

export default AddBike;
