// import React, { useState, useEffect, useCallback } from "react";
// import { Tabs, Tab, Table, Button, Form } from "react-bootstrap";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import withReactContent from "sweetalert2-react-content";
// const { APP_URL } = window["appconfig"];
// const MySwal = withReactContent(Swal);

// const PurchaseOrderForm = () => {
//   const [orderDetails, setOrderDetails] = useState({
//     order_no: "",
//     dealer_id: "",
//     po_date: "",
//     delivery_date: "",
//     status: "",
//     delivery_status: "",
//   });
//   const [items, setItems] = useState([]);
//   const [bikes, setBikes] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();
//   //   const APP_URL = "https://your-backend-url/api/"; // Replace with your backend URL

//   const fetchBikes = useCallback(async () => {
//     setIsLoading(true);
//     const token = localStorage.getItem("elfa_token");

//     try {
//       const response = await axios.get(`${APP_URL}bikes/get-bikes`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.status === 200) {
//         // Map the response to extract required fields
//         const mappedBikes = response.data.data.map((bike) => ({
//           id: bike.id,
//           chassisNumber: bike.chassis_number,
//           motorNumber: bike.motor_number,
//           imeiNumber: bike.imei_number,
//           color: bike.color,
//           batteryNumber: bike.battery_number,
//         }));
//         setBikes(mappedBikes);
//       } else {
//         setErrorMessage("Failed to load bikes. Please try again later.");
//         MySwal.fire({
//           title: "Error!",
//           text: "Failed to load bikes.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       console.error(error, "Error loading bikes");
//       setErrorMessage("An error occurred while loading bikes.");
//       if (error.response && error.response.status === 401) {
//         navigate("/evsystem/login");
//         localStorage.removeItem("elfa_token");
//         localStorage.removeItem("elfa_user_data");
//       }
//       MySwal.fire({
//         title: "Error!",
//         text: "An error occurred while loading bikes. Please try again later.",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     fetchBikes();
//   }, [fetchBikes]);

//   // Handle change for input fields
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setOrderDetails({ ...orderDetails, [name]: value });
//   };

//   // Add new item row
//   const handleAddItem = () => {
//     setItems([
//       ...items,
//       {
//         bike_id: "",
//         motor_no: "",
//         imei_id: "",
//         battery_id: "",
//         color: "",
//         chasis_id: "",
//         quantity: "",
//       },
//     ]);
//   };

//   // Handle item change
//   const handleItemChange = (index, field, value) => {
//     const updatedItems = [...items];
//     updatedItems[index][field] = value;

//     // Auto-fill based on selected bike
//     if (field === "bike_id") {
//       const selectedBike = bikes.find((bike) => bike.id === parseInt(value));
//       if (selectedBike) {
//         updatedItems[index] = {
//           ...updatedItems[index],
//           motor_no: selectedBike.motorNumber,
//           imei_id: selectedBike.imeiNumber,
//           battery_id: selectedBike.batteryNumber,
//           color: selectedBike.color,
//           chasis_id: selectedBike.chassisNumber,
//         };
//       }
//     }

//     setItems(updatedItems);
//   };

//   // Handle form submission
//   const handleSubmit = () => {
//     const payload = {
//       ...orderDetails,
//       details: items,
//     };

//     console.log(payload, "data to be posted");

//     return;

//     axios
//       .post(`${APP_URL}purchase-orders`, payload, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("elfa_token")}`,
//         },
//       })
//       .then((response) => {
//         console.log("Purchase order created:", response.data);
//         MySwal.fire({
//           title: "Success!",
//           text: "Purchase order created successfully.",
//           icon: "success",
//           confirmButtonText: "OK",
//         });
//       })
//       .catch((error) => {
//         console.error("Error creating purchase order:", error);
//         MySwal.fire({
//           title: "Error!",
//           text: "Failed to create purchase order. Please try again later.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       });
//   };

//   return (
//     <div className="container mt-4">
//       <h2>Create New Purchase Order</h2>
//       <Form>
//         <div className="row">
//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>PO No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="order_no"
//                 value={orderDetails.order_no}
//                 onChange={handleInputChange}
//                 placeholder={"PO-00001"}
//                 disabled
//               />
//             </Form.Group>
//           </div>

//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>Dealer</Form.Label>
//               <Form.Select
//                 name="dealer_id"
//                 value={orderDetails.dealer_id}
//                 onChange={handleInputChange}
//               >
//                 <option value="">Select Dealer</option>
//                 {/* {dealers.map((dealer) => (
//                   <option key={dealer.id} value={dealer.id}>
//                     {dealer.name}
//                   </option>
//                 ))} */}
//               </Form.Select>
//             </Form.Group>
//           </div>
//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>PO Date</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="po_date"
//                 value={orderDetails.po_date}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>Delivery Date</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="delivery_date"
//                 value={orderDetails.delivery_date}
//                 onChange={handleInputChange}
//                 required
//               />
//             </Form.Group>
//           </div>

//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>Status</Form.Label>
//               <Form.Select
//                 name="status"
//                 value={orderDetails.status}
//                 onChange={handleInputChange}
//               >
//                 <option value="">Select Status</option>
//                 <option value="1">Open</option>
//                 <option value="2">Approved</option>
//               </Form.Select>
//             </Form.Group>
//           </div>

//           <div className="col-md-4">
//             <Form.Group>
//               <Form.Label>Delivery Status</Form.Label>
//               <Form.Select
//                 name="delivery_status"
//                 value={orderDetails.delivery_status}
//                 onChange={handleInputChange}
//               >
//                 <option value="">Select Delivery Status</option>
//                 <option value="1">Pending</option>
//                 <option value="2">In Production</option>
//                 <option value="3">Production Complete</option>
//                 <option value="4">Ready for Delivery</option>
//                 <option value="5">Delivered</option>
//               </Form.Select>
//             </Form.Group>
//           </div>
//         </div>

//         {/* Tabs for Items and other sections */}
//         <Tabs defaultActiveKey="items" className="mt-4">
//           <Tab eventKey="items" title="Items">
//             <Table bordered hover className="mt-3">
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>Bike</th>
//                   <th>Motor No</th>
//                   <th>IMEI</th>
//                   <th>Battery</th>
//                   <th>Color</th>
//                   <th>Chasis No</th>
//                   <th>Quantity</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {items.map((item, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>
//                       <Form.Select
//                         value={item.bike_id}
//                         onChange={(e) =>
//                           handleItemChange(index, "bike_id", e.target.value)
//                         }
//                       >
//                         <option value="">Select Bike</option>
//                         {bikes.map((bike) => (
//                           <option key={bike.id} value={bike.id}>
//                             {`Bike ${bike.id} - ${bike.color}`}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </td>
//                     <td>
//                       <Form.Control
//                         type="text"
//                         value={item.motor_no}
//                         readOnly
//                       />
//                     </td>
//                     <td>
//                       <Form.Control type="text" value={item.imei_id} readOnly />
//                     </td>
//                     <td>
//                       <Form.Control
//                         type="text"
//                         value={item.battery_id}
//                         readOnly
//                       />
//                     </td>
//                     <td>
//                       <Form.Control type="text" value={item.color} readOnly />
//                     </td>
//                     <td>
//                       <Form.Control
//                         type="text"
//                         value={item.chasis_id}
//                         readOnly
//                       />
//                     </td>
//                     <td>
//                       <Form.Control
//                         type="number"
//                         value={item.quantity}
//                         onChange={(e) =>
//                           handleItemChange(index, "quantity", e.target.value)
//                         }
//                       />
//                     </td>
//                     <td>
//                       <Button
//                         className="button-fixed"
//                         variant="danger"
//                         onClick={() =>
//                           setItems(items.filter((_, i) => i !== index))
//                         }
//                       >
//                         Remove
//                       </Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//             <Button
//               variant="success"
//               className="button-fixed"
//               onClick={handleAddItem}
//             >
//               + Add Item
//             </Button>
//           </Tab>
//         </Tabs>

//         <Button
//           className="mt-4 button-fixed"
//           variant="primary"
//           onClick={handleSubmit}
//         >
//           Submit Purchase Order
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default PurchaseOrderForm;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab, Table, Button, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
const { APP_URL } = window["appconfig"];
const MySwal = withReactContent(Swal);

const PurchaseOrderForm = () => {
  const loggedInUserData = useSelector((state) => {
    console.log(state?.auth?.user, "state");
    return state?.auth?.user;
  });
  const [orderDetails, setOrderDetails] = useState({
    order_no: "",
    dealer_id: "",
    po_date: "",
    delivery_date: "",
    status: "",
    delivery_status: "",
    created_by_id: loggedInUserData?.user_id,
  });
  const [items, setItems] = useState([]);
  const [bikes, setBikes] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Fetch bikes (Unchanged)
  const fetchBikes = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem("elfa_token");

    try {
      const response = await axios.get(`${APP_URL}bikes/get-bikes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const mappedBikes = response.data.data.map((bike) => ({
          id: bike.id,
          chassisNumber: bike.chassis_number,
          motorNumber: bike.motor_number,
          imeiNumber: bike.imei_number,
          color: bike.color,
          batteryNumber: bike.battery_number,
        }));
        setBikes(mappedBikes);
      } else {
        setErrorMessage("Failed to load bikes. Please try again later.");
        MySwal.fire({
          title: "Error!",
          text: "Failed to load bikes.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error(error, "Error loading bikes");
      setErrorMessage("An error occurred while loading bikes.");
      if (error.response && error.response.status === 401) {
        navigate("/evsystem/login");
        localStorage.removeItem("elfa_token");
        localStorage.removeItem("elfa_user_data");
      }
      MySwal.fire({
        title: "Error!",
        text: "An error occurred while loading bikes. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch dealers (Users with role = 2)
  const fetchDealers = useCallback(async () => {
    const token = localStorage.getItem("elfa_token");
    try {
      const response = await axios.get(`${APP_URL}user/get-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const dealerData = response.data.data.filter(
          (user) => user.role === "2"
        );
        setDealers(dealerData);
      } else {
        MySwal.fire({
          title: "Error!",
          text: "Failed to load dealers.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error fetching dealers:", error);
      MySwal.fire({
        title: "Error!",
        text: "An error occurred while loading dealers. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, []);

  // Fetch the latest PO order number
  const fetchLatestOrderNumber = useCallback(async () => {
    const token = localStorage.getItem("elfa_token");
    try {
      const response = await axios.get(
        `${APP_URL}purchase-orders/get-latest-po-number`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include authorization token
          },
        }
      );
      if (response.status === 200) {
        setOrderDetails((prev) => ({
          ...prev,
          order_no: response.data.data, // Set the latest order number
        }));
      } else {
        console.error("Failed to fetch latest order number.");
      }
    } catch (error) {
      console.error("Error fetching latest PO order number:", error);
    }
  }, []);

  // Initialize data on component mount
  useEffect(() => {
    fetchBikes();
    fetchDealers();
    fetchLatestOrderNumber();
  }, [fetchBikes, fetchDealers, fetchLatestOrderNumber]);

  // Handle input change for fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails({ ...orderDetails, [name]: value });
  };

  // Add new item row
  const handleAddItem = () => {
    setItems([
      ...items,
      {
        bike_id: "",
        motor_no: "",
        imei_id: "",
        battery_id: "",
        color: "",
        chasis_id: "",
        quantity: "",
      },
    ]);
  };

  // Handle item field changes
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    // Auto-fill based on selected bike
    if (field === "bike_id") {
      const selectedBike = bikes.find((bike) => bike.id === parseInt(value));
      if (selectedBike) {
        updatedItems[index] = {
          ...updatedItems[index],
          motor_no: selectedBike.motorNumber,
          imei_id: selectedBike.imeiNumber,
          battery_id: selectedBike.batteryNumber,
          color: selectedBike.color,
          chasis_id: selectedBike.chassisNumber,
        };
      }
    }

    setItems(updatedItems);
  };

  // Handle form submission
  //   const handleSubmit = () => {
  //     // Validate order details
  //     const requiredFields = [
  //       { key: "order_no", label: "Order Number" },
  //       { key: "dealer_id", label: "Dealer" },
  //       { key: "po_date", label: "PO Date" },
  //       { key: "delivery_date", label: "Delivery Date" },
  //       { key: "status", label: "Status" },
  //       { key: "delivery_status", label: "Delivery Status" },
  //     ];

  //     // Check for missing fields in order details
  //     for (const field of requiredFields) {
  //       if (!orderDetails[field.key]) {
  //         MySwal.fire({
  //           title: "Error!",
  //           text: `Please fill the ${field.label} field.`,
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //         return; // Stop submission if any field is missing
  //       }
  //     }

  //     // Check if the details array is empty
  //     if (items.length === 0) {
  //       MySwal.fire({
  //         title: "Error!",
  //         text: "Please add at least one item.",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //       return; // Stop submission if no items are added
  //     }

  //     // Check each item in the details array for required fields
  //     for (const [index, item] of items.entries()) {
  //       const itemRequiredFields = [
  //         { key: "bike_id", label: "Bike" },
  //         { key: "quantity", label: "Quantity" },
  //       ];

  //       for (const field of itemRequiredFields) {
  //         if (!item[field.key]) {
  //           MySwal.fire({
  //             title: "Error!",
  //             text: `Please fill the ${field.label} field in item #${index + 1}.`,
  //             icon: "error",
  //             confirmButtonText: "OK",
  //           });
  //           return; // Stop submission if any item field is missing
  //         }
  //       }
  //     }
  //     const payload = {
  //       ...orderDetails,
  //       details: items,
  //     };

  //     console.log(payload, "data to be posted");

  //     // return;

  //     axios
  //       .post(`${APP_URL}purchase-orders/create`, payload, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("elfa_token")}`,
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Purchase order created:", response.data);
  //         MySwal.fire({
  //           title: "Success!",
  //           text: "Purchase order created successfully.",
  //           icon: "success",
  //           confirmButtonText: "OK",
  //         });

  //         // Clear form data
  //     setOrderDetails({
  //         order_no: "",
  //         dealer_id: "",
  //         po_date: "",
  //         delivery_date: "",
  //         status: "",
  //         delivery_status: "",
  //       });
  //       setItems([]);

  //       // Fetch the latest PO number
  //       const latestPOResponse = await axios.get(`${APP_URL}purchase-orders/get-latest-po-number`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("elfa_token")}`,
  //         },
  //       });

  //       if (latestPOResponse.status === 200) {
  //         const latestPONumber = latestPOResponse.data.data;
  //         setOrderDetails((prevState) => ({
  //           ...prevState,
  //           order_no: latestPONumber,
  //         }));
  //       } else {
  //         console.error("Failed to fetch the latest PO number.");
  //       }
  //       })
  //       .catch((error) => {
  //         console.error("Error creating purchase order:", error);
  //         MySwal.fire({
  //           title: "Error!",
  //           text: "Failed to create purchase order. Please try again later.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //       });
  //   };

  const handleSubmit = async () => {
    try {
      // Validate order details
      const requiredFields = [
        { key: "order_no", label: "Order Number" },
        { key: "dealer_id", label: "Dealer" },
        { key: "po_date", label: "PO Date" },
        { key: "delivery_date", label: "Delivery Date" },
        { key: "status", label: "Status" },
        { key: "delivery_status", label: "Delivery Status" },
      ];

      // Check for missing fields in order details
      for (const field of requiredFields) {
        if (!orderDetails[field.key]) {
          await MySwal.fire({
            title: "Error!",
            text: `Please fill the ${field.label} field.`,
            icon: "error",
            confirmButtonText: "OK",
          });
          return; // Stop submission if any field is missing
        }
      }

      // Check if the details array is empty
      if (items.length === 0) {
        await MySwal.fire({
          title: "Error!",
          text: "Please add at least one item.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Stop submission if no items are added
      }

      // Check each item in the details array for required fields
      for (const [index, item] of items.entries()) {
        const itemRequiredFields = [
          { key: "bike_id", label: "Bike" },
          { key: "quantity", label: "Quantity" },
        ];

        for (const field of itemRequiredFields) {
          if (!item[field.key]) {
            await MySwal.fire({
              title: "Error!",
              text: `Please fill the ${field.label} field in item #${
                index + 1
              }.`,
              icon: "error",
              confirmButtonText: "OK",
            });
            return; // Stop submission if any item field is missing
          }
        }
      }

      const payload = {
        ...orderDetails,
        details: items,
      };

      console.log(payload, "data to be posted");

      // Submit the form
      const response = await axios.post(
        `${APP_URL}purchase-orders/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("elfa_token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Purchase order created:", response.data);
        await MySwal.fire({
          title: "Success!",
          text: "Purchase order created successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Clear form data
        setOrderDetails({
          order_no: "",
          dealer_id: "",
          po_date: "",
          delivery_date: "",
          status: "",
          delivery_status: "",
        });
        setItems([]);

        // Fetch the latest PO number
        const latestPOResponse = await axios.get(
          `${APP_URL}purchase-orders/get-latest-po-number`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("elfa_token")}`,
            },
          }
        );

        if (latestPOResponse.status === 200) {
          const latestPONumber = latestPOResponse.data.data;
          setOrderDetails((prevState) => ({
            ...prevState,
            order_no: latestPONumber,
          }));
        } else {
          console.error("Failed to fetch the latest PO number.");
        }
      }
    } catch (error) {
      console.error("Error creating purchase order:", error);
      await MySwal.fire({
        title: "Error!",
        text: "Failed to create purchase order. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="container mt-4">
      <h2>Create New Purchase Order {loggedInUserData.user_id}</h2>
      <Form>
        {/* Fields */}
        <div className="row">
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>PO No.</Form.Label>
              <Form.Control
                type="text"
                name="order_no"
                value={orderDetails.order_no}
                disabled
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>Dealer</Form.Label>
              <Form.Select
                name="dealer_id"
                value={orderDetails.dealer_id}
                onChange={handleInputChange}
              >
                <option value="">Select Dealer</option>
                {dealers.map((dealer) => (
                  <option key={dealer.user_id} value={dealer.user_id}>
                    {dealer.user_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>PO Date</Form.Label>
              <Form.Control
                type="date"
                name="po_date"
                value={orderDetails.po_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>Delivery Date</Form.Label>
              <Form.Control
                type="date"
                name="delivery_date"
                value={orderDetails.delivery_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={orderDetails.status}
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                <option value="1">Open</option>
                <option value="2">Approved</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>Delivery Status</Form.Label>
              <Form.Select
                name="delivery_status"
                value={orderDetails.delivery_status}
                onChange={handleInputChange}
              >
                <option value="">Select Delivery Status</option>
                <option value="1">Pending</option>
                <option value="2">In Production</option>
                <option value="3">Production Complete</option>
                <option value="4">Ready for Delivery</option>
                <option value="5">Delivered</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>

        {/* Items Section */}
        <Tabs defaultActiveKey="items" className="mt-4">
          <Tab eventKey="items" title="Items">
            <Table bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Bike</th>
                  <th>Motor No</th>
                  <th>IMEI</th>
                  <th>Battery</th>
                  <th>Color</th>
                  <th>Chasis No</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Form.Select
                        value={item.bike_id}
                        onChange={(e) =>
                          handleItemChange(index, "bike_id", e.target.value)
                        }
                      >
                        <option value="">Select Bike</option>
                        {bikes.map((bike) => (
                          <option key={bike.id} value={bike.id}>
                            {`Bike ${bike.id} - ${bike.color}`}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.motor_no}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control type="text" value={item.imei_id} readOnly />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.battery_id}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control type="text" value={item.color} readOnly />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.chasis_id}
                        readOnly
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleItemChange(index, "quantity", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        className="button-fixed"
                        onClick={() =>
                          setItems(items.filter((_, i) => i !== index))
                        }
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              variant="success"
              className="button-fixed"
              onClick={handleAddItem}
            >
              + Add Item
            </Button>
          </Tab>
        </Tabs>

        <Button
          className="mt-4 button-fixed"
          variant="primary"
          onClick={handleSubmit}
        >
          Submit Purchase Order
        </Button>
      </Form>
    </div>
  );
};

export default PurchaseOrderForm;
