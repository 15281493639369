import React, { useEffect } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
const OurStory = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Header />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    About Us<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-6">
                  <div class="position-relative p-4">
                    <div
                      class="bg-color text-light text-center w-30 p-3 rounded-10px position-absolute top-20 end-0 z-index-2 wow zoomIn"
                      data-wow-delay=".0s"
                    >
                      <i class="icofont-bulb-alt fs-48"></i>
                      <h5 class="">Innovation</h5>
                    </div>

                    <img
                      src="images/misc/15.webp"
                      class="img-fluid rounded-20px position-relative z-index-1 wow fadeInRight"
                      alt=""
                    />

                    <div class="position-absolute bg-color-2 w-90 h-80 bg-color p-3 bottom-0 start-10 rounded-20px wow fadeInLeft"></div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="subtitle wow fadeInUp mb-3">Who We Are</div>
                  <h2>
                    Welcome to The <span class="id-color">ELFA Electric</span>
                  </h2>
                  <p class="lead fw-bold">
                    ELFA Electric, born under EVTechGlobal at Wavetec, is driven
                    by a passion for building an electric future with
                    sustainable, affordable, and innovative solutions powered by
                    clean technology.
                  </p>
                  <p>
                    At ELFA Electric, we believe that every person and every
                    detail matters. Our electric motorcycles are meticulously
                    designed, engineered, and rigorously tested for the local
                    rider, using top-quality components. We’ve studied driving
                    patterns, road conditions, and the needs of different types
                    of riders to determine the optimal specifications, including
                    the selection of the right motor size, battery, and battery
                    management system for our EV motorcycles. This
                    customer-centric approach to developing solutions is backed
                    by EV Technologies’ expertise and Wavetec’s 30-year legacy
                    of R&D and technology, which has successfully powered both
                    global and local companies.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="jarallax text-light pt30 pb30">
            <img
              src="images/background/gradient-3.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="wow fadeInRight d-flex">
              <div class="de-marquee-list wow">
                <div class="d-item">
                  <span class="d-item-txt">Our Vision</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Mission</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Vision</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Mission</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Vision</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Mission</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Vision</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Our Mission</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-4">
                  <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
                    Vision &amp; Mission
                  </div>
                  <h2>For a Cleaner, and Brighter Future</h2>
                  <img src="images/misc/17.webp" class="img-fluid" alt="" />
                </div>

                <div class="col-lg-8">
                  <h4 class="mb-4 wow fadeInRight">Our Vision</h4>
                  <p class="lead wow fadeInUp">
                    Transforming perceptions of motorbikes and their
                    environmental impact, for a greener tomorrow.
                  </p>

                  <div class="spacer-single"></div>

                  <h4 class="mb-4 wow fadeInRight">Our Mission</h4>

                  <p class="lead wow fadeInUp">
                    Embrace Larry Page’s vision – “Revolutionary change, not
                    incremental change.” We’re not just altering the landscape;
                    we’re reshaping the future of transportation.
                  </p>
                  {/* <ol class="ol-style-1">
                    <li class="wow fadeInUp" data-wow-delay=".2s">
                      <span class="fw-bold id-color">Innovation:</span> We
                      strive to push the boundaries of technological
                      advancement, continuously innovating in electric vehicle
                      design, battery technology, and sustainable manufacturing
                      processes.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".4s">
                      <span class="fw-bold id-color">Sustainability:</span> Our
                      commitment to sustainability is unwavering. We aim to
                      reduce carbon emissions and minimize environmental impact
                      throughout the entire lifecycle of our products,
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".6s">
                      <span class="fw-bold id-color">Quality & Safety:</span>{" "}
                      The safety and satisfaction of our customers are
                      paramount. We uphold the highest standards of quality,
                      safety, and reliability in every vehicle we produce
                    </li>
                  </ol> */}
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-6">
                  <div class="subtitle wow fadeInUp mb-3">Core Value</div>
                  <h2>
                    What Sets Us <span class="id-color">Apart</span>
                  </h2>
                  <p class="lead fw-bold">
                    We are leading the way to a cleaner, greener future by:
                  </p>
                  <p>
                    Providing exceptional after-sales service to ensure peace of
                    mind: We offer comprehensive support and maintenance to help
                    you feel confident in your switch to electric motorcycles.
                  </p>

                  <p>
                    Creating high-quality, reliable products optimized for local
                    conditions: Our motorcycles are built to withstand the
                    unique challenges of local roads and weather.
                  </p>

                  <p>
                    Offering affordable solutions that cater to everyone: Our
                    goal is to make electric motorcycles accessible to all.
                  </p>

                  {/* <div class="d-flex">
                    <ul class="ul-style-2">
                      <li>Zero Emissions</li>
                      <li>Lower Costs</li>
                      <li>DDoS Mitigation</li>
                    </ul>

                    <ul class="ul-style-2 ml-30">
                      <li>Regenerative Braking</li>
                      <li>Quiet Operation</li>
                      <li>Advanced Technology</li>
                    </ul>
                  </div> */}
                </div>

                <div class="col-lg-6">
                  <div class="position-relative p-4">
                    <div
                      class="bg-color text-light text-center w-30 p-3 rounded-10px position-absolute top-20 start-0 z-index-2 wow zoomIn"
                      data-wow-delay=".0s"
                    >
                      <i class="icofont-bulb-alt fs-48"></i>
                      <h5 class="">Innovation</h5>
                    </div>

                    <img
                      src="images/misc/16.webp"
                      class="img-fluid rounded-20px position-relative z-index-1 wow fadeInLeft"
                      alt=""
                    />

                    <div class="position-absolute bg-color-2 w-90 h-80 bg-color p-3 bottom-0 end-10 rounded-20px wow fadeInRight"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 
          <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-6">
                  <div class="subtitle ow fadeInUp mb-3">Behind the Scene</div>
                  <h2 class="wow fadeInUp mb-0" data-wow-delay=".2s">
                    Our Team
                  </h2>
                </div>
                <div class="clearfix"></div>
                <div class="col-lg-3 col-sm-6">
                  <div class="relative">
                    <img
                      src="images/team/1.webp"
                      class="img-fluid rounded-10px"
                      alt=""
                    />
                    <div
                      class="p-3 text-center
                                "
                    >
                      <h4 class="mb-0">Jeffery Mussman</h4>
                      <p class="mb-2">Founder &amp; CEO</p>
                      <div class="social-icons">
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-x-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                  <div class="relative">
                    <img
                      src="images/team/2.webp"
                      class="img-fluid rounded-10px"
                      alt=""
                    />
                    <div
                      class="p-3 text-center
                                "
                    >
                      <h4 class="mb-0">Olivia Grace Parker</h4>
                      <p class="mb-2">Founder &amp; CEO</p>
                      <div class="social-icons">
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-x-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                  <div class="relative">
                    <img
                      src="images/team/3.webp"
                      class="img-fluid rounded-10px"
                      alt=""
                    />
                    <div
                      class="p-3 text-center
                                "
                    >
                      <h4 class="mb-0">Jacob Williams</h4>
                      <p class="mb-2">Founder &amp; CEO</p>
                      <div class="social-icons">
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-x-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                  <div class="relative">
                    <img
                      src="images/team/4.webp"
                      class="img-fluid rounded-10px"
                      alt=""
                    />
                    <div
                      class="p-3 text-center
                                "
                    >
                      <h4 class="mb-0">Ethan Johnson</h4>
                      <p class="mb-2">Founder &amp; CEO</p>
                      <div class="social-icons">
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-x-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="bg-color text-white bg-hover-2 text-hover-white fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section class="pt60 pb60" style={{ backgroundColor: "azure" }}>
            <div class="container">
              <div class="row g-4">
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 text-center wow fadeInRight"
                    data-wow-delay=".0s"
                  >
                    <h3 class="fs-40">
                      <span
                        class="timer fs-40"
                        data-to="28950"
                        data-speed="3000"
                      >
                        0
                      </span>
                      +
                    </h3>
                    Happy Customers
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 text-center wow fadeInRight"
                    data-wow-delay=".2s"
                  >
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="240" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Charger Stations
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 text-center wow fadeInRight"
                    data-wow-delay=".4s"
                  >
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="158" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Skilled Technicians
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 text-center wow fadeInRight"
                    data-wow-delay=".6s"
                  >
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="20" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Countries
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
        {/* <!-- content close --> */}

        <Footer />
      </div>
    </>
  );
};

export default OurStory;
