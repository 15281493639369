// src/screens/Dashboard/ListBikes.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import DataTable from "react-data-table-component"; // Import the DataTable library
import {
  Container,
  Table,
  Spinner,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
// import { FaDownload, FaUpload, FaFileCsv } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const { APP_URL } = window["appconfig"];
const MySwal = withReactContent(Swal);

const ListBikes = () => {
  const [bikes, setBikes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [csvFile, setCsvFile] = useState(null);
  const fileInputRef = useRef(null); // Reference for file input

  const navigate = useNavigate();
  //   const bikes = [
  //     {
  //       id: 1,
  //       chassis_number: "CH123456789",
  //       motor_number: "MT123456789",
  //       imei_number: "IMEI123456789",
  //       color: "Red",
  //       battery_number: "BAT123456789",
  //     },
  //     {
  //       id: 2,
  //       chassis_number: "CH987654321",
  //       motor_number: "MT987654321",
  //       imei_number: "IMEI987654321",
  //       color: "Blue",
  //       battery_number: "BAT987654321",
  //     },
  //   ];

  //   useEffect(() => {
  //     const fetchBikes = async () => {
  //       setIsLoading(true); // Start loading
  //       const token = localStorage.getItem("elfa_token"); // Get token from localStorage

  //       try {
  //         const response = await axios.get(`${APP_URL}bikes/get-bikes`, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //             // Pass token in headers
  //           },
  //         });

  //         console.log(response, "Get Bikes API Response");

  //         if (response.status === 200) {
  //           setBikes(response?.data?.data); // Assuming API response contains bikes array

  //           //   MySwal.fire({
  //           //     title: "Success!",
  //           //     text: "Bikes loaded successfully!",
  //           //     icon: "success",
  //           //     confirmButtonText: "OK",
  //           //   });

  //           setIsLoading(false); // Stop loading
  //         } else {
  //           console.log(response, "Error in response status");
  //           setErrorMessage("Failed to load bikes. Please try again later.");

  //           MySwal.fire({
  //             title: "Error!",
  //             text: "Failed to load bikes.",
  //             icon: "error",
  //             confirmButtonText: "OK",
  //           });

  //           setIsLoading(false);
  //         }
  //       } catch (error) {
  //         console.error(error, error.status, "Error loading bikes");

  //         if (error.response && error.response.status === 401) {
  //           MySwal.fire({
  //             title: "Error!",
  //             text: "Unauthorized access. Please log in.",
  //             icon: "error",
  //             confirmButtonText: "OK",
  //           });

  //           navigate("/evsystem/login");
  //           localStorage.removeItem("elfa_token"); // Clear token
  //           localStorage.removeItem("elfa_user_data"); // Clear user data
  //         } else {
  //           MySwal.fire({
  //             title: "Error!",
  //             text: "An error occurred while loading bikes. Please try again later.",
  //             icon: "error",
  //             confirmButtonText: "OK",
  //           });
  //           navigate("/evsystem/login");
  //           localStorage.removeItem("elfa_token"); // Clear token
  //           localStorage.removeItem("elfa_user_data"); // Clear user data
  //         }

  //         setErrorMessage("An error occurred while loading bikes.");
  //         setIsLoading(false);
  //         navigate("/evsystem/login");
  //         localStorage.removeItem("elfa_token"); // Clear token
  //         localStorage.removeItem("elfa_user_data"); // Clear user data
  //       }
  //     };

  //     fetchBikes();
  //   }, []);

  // Function to handle file input change

  // Define fetchBikes with useCallback for reusability
  const fetchBikes = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem("elfa_token");

    try {
      const response = await axios.get(`${APP_URL}bikes/get-bikes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setBikes(response.data.data);
        console.log(response.data.data, "bikes data---");
      } else {
        setErrorMessage("Failed to load bikes. Please try again later.");
        MySwal.fire({
          title: "Error!",
          text: "Failed to load bikes.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error(error, "Error loading bikes");
      setErrorMessage("An error occurred while loading bikes.");
      if (error.response && error.response.status === 401) {
        navigate("/evsystem/login");
        localStorage.removeItem("elfa_token");
        localStorage.removeItem("elfa_user_data");
      }
      MySwal.fire({
        title: "Error!",
        text: "An error occurred while loading bikes. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchBikes();
  }, [fetchBikes]);

  const handleFileChange = (e) => {
    console.log(e.target.files[0], "uploaded filesss");
    setCsvFile(e.target.files[0]);
  };

  // Function to upload CSV
  //   const uploadCSV = async () => {
  //     if (!csvFile) {
  //       MySwal.fire({
  //         title: "Error!",
  //         text: "Please select a CSV file to upload.",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //       return;
  //     }

  //     const token = localStorage.getItem("elfa_token");
  //     const formData = new FormData();
  //     formData.append("file", csvFile);

  //     try {
  //       const response = await axios.post(
  //         `${APP_URL}bikes/upload-csv`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       console.log(response, "response----");

  //       if (response.status === 200) {
  //         MySwal.fire({
  //           title: "Success!",
  //           text: "CSV uploaded successfully!",
  //           icon: "success",
  //           confirmButtonText: "OK",
  //         });
  //         setCsvFile(null); // Clear file input
  //         if (fileInputRef.current) fileInputRef.current.value = ""; // Clear file input value
  //         fetchBikes(); // Re-fetch bikes after successful upload
  //       } else {
  //         MySwal.fire({
  //           title: "Error!",
  //           text: "Failed to upload CSV. Please try again.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });

  //         setCsvFile(null); // Clear file input
  //         if (fileInputRef.current) fileInputRef.current.value = ""; // Clear file input value
  //       }
  //     } catch (error) {
  //       console.log(error, "Error---");
  //       const errorMesg = error?.response?.data?.message;
  //       MySwal.fire({
  //         title: "Error!",
  //         text: errorMesg ? errorMesg : "An error occurred during CSV upload",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });

  //       setCsvFile(null); // Clear file input
  //       if (fileInputRef.current) fileInputRef.current.value = ""; // Clear file input value
  //     }
  //   };

  //   Running code 27/11/2024
  const uploadCSV = async () => {
    if (!csvFile) {
      MySwal.fire({
        title: "Error!",
        text: "Please select a CSV file to upload.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const token = localStorage.getItem("elfa_token");
    const formData = new FormData();
    formData.append("file", csvFile);

    try {
      const response = await axios.post(
        `${APP_URL}bikes/upload-csv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response, "response----");

      if (response.status === 200) {
        const { inserted, errors } = response.data.data;

        if (errors && errors.length > 0) {
          // Build the error message dynamically with row numbers
          const errorMessages = errors
            .map(
              (err, index) =>
                `<p><strong>Error ${index + 1}:</strong> ${err.error}</p>`
            )
            .join("");

          // Show errors in a SweetAlert
          MySwal.fire({
            title: "Errors Found!",
            html: `
                <p>The following issues occurred during CSV upload:</p>
                ${errorMessages}
              `,
            icon: "warning",
            confirmButtonText: "OK",
          });

          // Log detailed errors with row numbers
          console.error("Errors in CSV upload:", errors);
        } else {
          // All rows successfully inserted
          MySwal.fire({
            title: "Success!",
            text: "CSV uploaded successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
        }

        // Clear file input and refresh data
        setCsvFile(null);
        if (fileInputRef.current) fileInputRef.current.value = "";
        fetchBikes(); // Re-fetch bikes after successful upload
      } else {
        MySwal.fire({
          title: "Error!",
          text: "Failed to upload CSV. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });

        setCsvFile(null); // Clear file input
        if (fileInputRef.current) fileInputRef.current.value = ""; // Clear file input value
      }
    } catch (error) {
      //   console.log(error, "Error---");
      const errorMesg = error?.response?.data?.message;
      MySwal.fire({
        title: "Error!",
        text: errorMesg ? errorMesg : "An error occurred during CSV upload",
        icon: "error",
        confirmButtonText: "OK",
      });

      setCsvFile(null); // Clear file input
      if (fileInputRef.current) fileInputRef.current.value = ""; // Clear file input value
    }
  };

  // Function to download sample CSV
  const downloadSampleCSV = async () => {
    const token = localStorage.getItem("elfa_token"); // Get token from localStorage

    // return;
    try {
      const response = await axios.get(`${APP_URL}bikes/download-sample-csv`, {
        headers: {
          "Content-Type": "application/csv",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Important for handling binary data
      });

      // Create a URL for the blob and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample_bikes.csv"); // Specify the filename
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading sample CSV:", error);
      MySwal.fire({
        title: "Error!",
        text: "Failed to download the sample CSV file.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  //   const downloadBikesCSV = () => {
  //     const csvContent = [
  //       [
  //         "chassis_number",
  //         "motor_number",
  //         "imei_number",
  //         "color",
  //         "battery_number",
  //       ],
  //       ...bikes.map((bike) => [
  //         bike.chassis_number,
  //         bike.motor_number,
  //         bike.imei_number,
  //         bike.color,
  //         bike.battery_number,
  //       ]),
  //     ]
  //       .map((row) => row.join(","))
  //       .join("\n");

  //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = "bikes_data.csv";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  //   const downloadBikesCSV = () => {
  //     // Define the column headers dynamically
  //     const columnHeaders = [
  //       "model",
  //       "color",
  //       "chassis_id",
  //       "motor_no",
  //       "imei_id",
  //       "battery_id",
  //     ];

  //     // Map the bikes data to match the column headers
  //     const csvContent = [
  //       columnHeaders, // Add the headers as the first row
  //       ...bikes.map(
  //         (bike) => columnHeaders.map((header) => bike[header] || "") // Extract values dynamically
  //       ),
  //     ]
  //       .map((row) => row.join(",")) // Convert rows to CSV format
  //       .join("\n"); // Join rows with newlines

  //     // Create a Blob for the CSV and trigger download
  //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = "bikes_data.csv";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  const downloadBikesCSV = () => {
    const columnHeaders = [
      //   "Bike ID",
      "Model",
      "Color",
      "Chassis ID",
      "Motor No",
      "IMEI ID",
      "Battery ID",
      //   "Status",
    ];

    const csvContent = [
      columnHeaders,
      ...bikes.map((bike) => [
        // bike.bike_id,
        bike.model, // Include model
        bike.color, // Include color
        bike.chassis_id,
        bike.motor_no,
        bike.imei_id,
        bike.battery_id,
        // bike.status,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "bikes_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    { name: "Model", selector: (row) => row.model },
    { name: "Color", selector: (row) => row.color },
    { name: "Chassis ID", selector: (row) => row.chassis_id },
    { name: "Motor Number", selector: (row) => row.motor_no },
    { name: "IMEI ID", selector: (row) => row.imei_id },
    { name: "Battery ID", selector: (row) => row.battery_id },
  ];

  return (
    <Container className="mt-4">
      <Row className="align-items-center mb-3">
        <Col md={2}>
          <h3>List of Bikes</h3>
        </Col>
        <Col md={10} className="d-flex flex-column">
          {/* First Row of Buttons */}
          <Row className="mb-2 justify-content-end">
            <Col xs="auto">
              <Button
                variant="primary"
                onClick={downloadSampleCSV}
                className="button-fixed"
              >
                Download Sample CSV
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="info"
                onClick={downloadBikesCSV}
                className="button-fixed"
              >
                Download CSV
              </Button>
            </Col>
          </Row>

          {/* Second Row of Buttons */}
          <Row className="justify-content-end">
            <Col xs="auto">
              <input
                ref={fileInputRef} // Reference to clear input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-upload"
              />
              <Button
                as="label"
                htmlFor="file-upload"
                variant="secondary"
                className="button-fixed"
                style={{ cursor: "pointer" }}
              >
                Select File
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="success"
                onClick={uploadCSV}
                className="button-fixed"
              >
                Upload CSV
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Table striped bordered hover responsive className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Chassis Number</th>
            <th>Motor Number</th>
            <th>IMEI Number</th>
            <th>Color</th>
            <th>Battery Number</th>
          </tr>
        </thead>
        <tbody>
          {bikes.map((bike) => (
            <tr key={bike.id}>
              <td>{bike.id}</td>
              <td>{bike.chassis_number}</td>
              <td>{bike.motor_number}</td>
              <td>{bike.imei_number}</td>
              <td>{bike.color}</td>
              <td>{bike.battery_number}</td>
            </tr>
          ))}
        </tbody>
      </Table> */}

      <DataTable
        columns={columns}
        data={bikes}
        progressPending={isLoading}
        pagination
        paginationPerPage={10}
        highlightOnHover
        responsive
      />
    </Container>
  );
};

export default ListBikes;
