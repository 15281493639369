// config/AuthGuard.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector((state) => {
    console.log(state, "state");
    return state.auth.isAuthenticated;
  });

  if (!isAuthenticated) {
    return <Navigate to="/evsystem/login" />;
  }

  return children;
};

export default AuthGuard;
